import { Injectable, OnDestroy } from "@angular/core";
import { NavigationStart, Router } from "@angular/router";
import { ReplaySubject } from "rxjs";
import { UserInfoService } from "../../services/user-info.service";
import { NgxHealthPlanService } from "../../features/health-plan/ngx-health-plan.service";


@Injectable({
  providedIn: "root",
})
export class FabButtonService implements OnDestroy {

  showAtLocations: string[] = [];
  shouldShowFAB = new ReplaySubject<boolean>(1);
  atLocation = new ReplaySubject<NavigationStart>(1);
  isSubMenuOpen = new ReplaySubject<boolean>(1);
  closeDialog = new ReplaySubject<boolean>(1);
  hasActiveHealthPlan = new ReplaySubject<boolean>(1);
  private subs = [];

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  constructor(private router: Router,
              private userService: UserInfoService,
              private healthPlanService: NgxHealthPlanService) {

    this.setShowLocations();


    let routerSub = router.events.subscribe((event) => {
      // Set locations where we show main FAB button
      if (event instanceof NavigationStart) {
        this.atLocation.next(event);
        this.closeDialog.next(true);
        if (this.showAtLocations.some(substring => substring.trim() === event.url.trim())) {
          this.shouldShowFAB.next(true);
        } else if (event.url == "/") { // at root aka main page
          this.shouldShowFAB.next(true);
        } else {
          this.shouldShowFAB.next(false);
        }
      }
    });

    const healthPlanChangeSub = this.healthPlanService.healthPlanChanges.subscribe(() => {
      if (!this.userService.getSessionContext()) return;
      let personId = this.userService.getSessionContext().principal.id;
      let activePlan = this.healthPlanService.getActiveHealthPlan(personId);
      if (activePlan) {
        this.hasActiveHealthPlan.next(true);
      } else {
        this.hasActiveHealthPlan.next(false);
      }
    });
    this.subs.push(...[routerSub, healthPlanChangeSub]);
  }

  // Where we show main FAB button
  private setShowLocations() {

    // at health plan
    this.showAtLocations.push("/health-plan/progress");
    this.showAtLocations.push("/health-plan/medication");
    this.showAtLocations.push("/health-plan/activities");

    // at diary
    this.showAtLocations.push("/diary/blood-pressure");
    this.showAtLocations.push("/diary/blood-sugar");
    this.showAtLocations.push("/diary/asthma-pef");
    this.showAtLocations.push("/diary/measurements");

    // when is adding activity report
    this.showAtLocations.push("/health-plan/new");
  }
}
