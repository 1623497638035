<ng-container *ngIf="isReady">

  <!--Always have activity-->
  <div (click)="onClickOption('/health-plan/new')"
       [@openClose]="isOpen"
       [ngStyle]="{'bottom.px': bottomDistance+10, 'right.px':
rightLabelPadding}"
       class="sub-menu-label"
       role="button">{{ 'healthPlan.activities' | translate }}
  </div>
  <button (click)="onClickOption('/health-plan/new')" [@openClose]="isOpen" [ngStyle]="{'bottom.px': bottomDistance,'right.px': rightButtonPadding}"
          aria-label="activity-button"
          class="sub-menu-button"
          mat-fab>
    <mat-icon [svgIcon]="'low-activity'"
              class="icon-color-blue" style="transform: scale(1.5); stroke-width: 1.7px;"></mat-icon>
  </button>
  <!--Goal measurement links-->
  <ng-container *ngIf="allGoals.length > 0">
    <ng-container *ngFor="let goal of allGoals; index as i">

      <ng-container *ngIf="goal.typeId == goalTypes.BLOOD_PRESSURE">
        <ng-content *ngTemplateOutlet="subMenuButton;context:{iteration:i, translate:  'diary.blood-pressure',
      link: '/diary/blood-pressure/new', icon: 'blood-pressure', scale: 1.5}"></ng-content>
      </ng-container>

      <ng-container *ngIf="goal.typeId == goalTypes.PEF">
        <ng-content *ngTemplateOutlet="subMenuButton;context:{iteration:i, translate:  'diary.asthma-pef',
      link: '/diary/asthma-pef/new', icon: 'asthma', scale: 1.5}"></ng-content>
      </ng-container>

      <ng-container *ngIf="goal.typeId == goalTypes.WEIGHT">
        <ng-content *ngTemplateOutlet="subMenuButton;context:{iteration:i, translate:  'diary.measurements',
      link: '/diary/measurements/new', icon: 'measurements', scale: 1.8}"></ng-content>
      </ng-container>

      <ng-container *ngIf="goal.typeId == goalTypes.BLOOD_SUGAR">
        <ng-content *ngTemplateOutlet="subMenuButton;context:{iteration:i, translate:  'diary.blood-sugar',
      link: '/diary/blood-sugar/new', icon: 'blood-sugar', scale: 1.8}"></ng-content>
      </ng-container>

    </ng-container>
  </ng-container>

</ng-container>


<!--BUTTON WITH LABEL-->
<ng-template #subMenuButton let-Icon="icon" let-Iteration="iteration" let-Link="link" let-Scale="scale"
             let-Translate="translate">
  <div (click)="onClickOption(Link)"
       [@openClose]="isOpen"
       [ngStyle]="{'bottom.px': (bottomDistanceMeasure+10)+(Iteration * 70), 'right.px':
      rightLabelPadding}"
       class="sub-menu-label"
       role="button">
    {{ Translate | translate }}
  </div>
  <button #button (click)="onClickOption(Link)" [@openClose]="isOpen" [ngStyle]="{'bottom.px': bottomDistanceMeasure + (Iteration * 70), 'right.px': rightButtonPadding}"
          aria-label="button"
          class="sub-menu-button"
          mat-fab>
    <mat-icon [style.transform]="'scale('+Scale+')'" [svgIcon]="Icon"
              class="icon-color-blue"></mat-icon>
  </button>
</ng-template>
