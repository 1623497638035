import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { FetchClassificatorsService, GoalTypes } from "../../services/fetch-classificators.service";
import { HealthPlanGoals } from "../../models/health-plan.model";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { NgxHealthPlanService } from "../../features/health-plan/ngx-health-plan.service";
import { TranslateModule } from "@ngx-translate/core";
import { MatIcon } from "@angular/material/icon";
import { MatFabButton } from "@angular/material/button";
import { NgIf, NgStyle, NgFor, NgTemplateOutlet, CommonModule } from "@angular/common";
import { TooltipModule } from "@amin-karimi/ng2-tooltip-directive";
import { TooltipComponent } from "../tooltip/tooltip.component";

@Component({
  selector: "app-fab-button-sub-menu",
  templateUrl: "./fab-button-sub-menu.component.html",
  styleUrls: ["./fab-button-sub-menu.component.css"],
  animations: [
    trigger("openClose", [
      state("open", style({
        opacity: 1,
        transform: "translateY(0)",
      })),
      state("closed", style({
        opacity: 0,
        transform: "translateY(5vh)",
      })),
      transition("closed => open", [
        animate("0.2s"),
      ]),
      transition("open => closed", [
        animate("0.2s"),
      ]),
    ]),
  ],
  standalone: true,
  imports: [
    NgIf,
    NgStyle,
    MatFabButton,
    MatIcon,
    NgFor,
    NgTemplateOutlet,
    TranslateModule,
    TooltipModule,
    CommonModule,
    TooltipComponent,
  ],
})

export class FabButtonSubMenuComponent implements OnInit, OnDestroy, AfterViewInit {
  private subs = [];
  goalTypes: GoalTypes;
  allGoals: HealthPlanGoals[];
  bottomDistance = 90;
  bottomDistanceMeasure = 160;
  rightLabelPadding = 95;
  rightButtonPadding = 20;
  isOpen = "closed";
  isReady = false;

  constructor(private router: Router,
              private healthPlanService: NgxHealthPlanService,
              private classificatorsService: FetchClassificatorsService) {
  }

  ngOnInit(): void {
    let goalTypeSub = this.classificatorsService.goalTypes.subscribe(data => this.goalTypes = data);
    let goalSub = this.healthPlanService.goalsChanges.subscribe((data) => {
      this.allGoals = data;
      this.allGoals = this.allGoals.filter((goal) => goal.typeId != this.goalTypes.MOTIVATIONAL);
      this.isReady = true;
    });
    this.subs.push(goalSub);
    this.subs.push(goalTypeSub);
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.isOpen = "open";
    }, 10);
  }

  ngOnDestroy(): void {
    this.isOpen = "closed";
    this.subs.forEach(sub => sub.unsubscribe());
  }

  onClickOption(url: string) {
    this.router.navigate([url]).then(() => console.log("navigating to: " + url));
  }
}
